// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../css/application.scss";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
global.toastr = require("toastr")
import "inline-attachment/src/inline-attachment";
import "inline-attachment/src/codemirror-4.inline-attachment";
import 'simplemde/dist/simplemde.min.css'
import SimpleMDE from "simplemde";
import 'codemirror/lib/codemirror.css';
import 'codemirror/lib/codemirror.js';
//import LazyLoad from "vanilla-lazyload"

// document.addEventListener('turbolinks:load', (event) => {
// const lazyLoadOptions = {
// 	elements_selector: ".lazy",
// 	//use_native: true
// 	};
// const pageLazyLoad = new LazyLoad(lazyLoadOptions);
// pageLazyLoad.update();
// })


document.addEventListener('turbolinks:load', (event) => {


  var ed = document.querySelectorAll("#editor");

  ed.forEach((editor) => {

     //Make textarea a Markdown editor
      const simplemde = new SimpleMDE({
        element: editor,
      });
      if (editor) {
      //Drag the image into the editor&Processing when dropped
      inlineAttachment.editors.codemirror4.attach(simplemde.codemirror, {
        uploadUrl: "/admin/docs/attach", //Destination URL to POST
        uploadFieldName: "image", //File field name(Key when fetching with params)
        allowedTypes: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
        extraHeaders: {
          "X-CSRF-Token": Rails.csrfToken()
        }, //CSRF measures
      });
      }
    });

  });
 


document.addEventListener("turbolinks:load", () => {

  // Parse the URL parameter
  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  
  // Clean URL
  function cleanURL() {
  	var uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  }
  
  // Give the parameter a variable name
  var action = getParameterByName('action');
  var body = document.body;


  if (action == 'billing-update-success') {
    body.classList.add("billing-update-success");
  }

  if (body.classList.contains("billing-update-success")) {
    toastr.success('Your billing info is updated');
    cleanURL();

  }

  if (action == 'subscription-success') {
    body.classList.add("subscription-success");
  }

  if (body.classList.contains("subscription-success")) {
    toastr.success('You have subscribed successfully');
    cleanURL();

  }

  if (action == 'subscription-failed') {
    body.classList.add("subscription-failed");
  }

  if (body.classList.contains("subscription-failed")) {
    toastr.error('Something went wrong!');
    cleanURL();
  }


  if (action == 'purchase-success') {
    body.classList.add("purchase-success");
  }

  if (body.classList.contains("purchase-success")) {
    toastr.success('You have Successfully purchased the theme');
    cleanURL();

  }

  if (action == 'purchase-failed') {
    body.classList.add("purchase-failed");
  }

  if (body.classList.contains("purchase-failed")) {
    toastr.error('Something went wrong!');
    cleanURL();
  }
  
  
  //mobile-menu
  var itBurger = document.querySelector('.it-burger');
  itBurger.addEventListener('click', function() {
      body.classList.toggle("it-head-open");
  });


  //Sticky Nav
  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
    ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
    });
});


document.addEventListener('turbolinks:load', (event) => {
  const t = document.querySelector(".it-header-dropdown");
  if (t) {
  t.addEventListener("click", function () {
    t.classList.toggle("it-sub-active");
  });
  }
})

document.addEventListener('turbolinks:click', function (event) {
  if (event.target.getAttribute('href').charAt(0) === '#') {
    return event.preventDefault()
  }
})